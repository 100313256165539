<template lang="pug">
v-dialog.mb-15(
  v-model="dialogAchievement",
  :scrollable="true",
  max-width="100%",
  persistent
)
  v-card
    v-container.container--fluid.card_header.px-0.pb-0.ml-auto.mr-auto(
      style="max-width: 1185px"
    )
      v-card-title.d-block.py-0.px-0.px-sm-5.px-md-12
        .title_name.d-flex.align-center.justify-center
          svg-icon(
            iconClass="gc_achievement_icon",
            className="gc_achievement_icon"
          )
          .league_name.ml-1 成就收藏冊
          span.text_gray.ml-2(style="font-size: 14px") ACHIEVEMENT
        .tabs
          v-tabs.achievement-sheet.position-relative(
            v-model="tab",
            ripple="false",
            color="#002442"
          )
            v-tabs-slider(hidden="true")
            v-tab.achievement_tab(:ripple="false", style="background: #fcf8ee") 週成就
            v-tab.achievement_tab(:ripple="false", style="background: #f3f3f3") 月成就
    v-card-text.container.container--fluid.px-0.px-sm-5.px-md-12(
      style="max-width: 1185px"
    )
      .achievement_brochure.week.pb-6.px-7(v-if="tab == 0")
        .selector_container.d-flex.align-center.justify-space-between
          v-btn.selected.select_week(
            v-if="seasonData != null && seasonData.end_months != null && seasonData.end_months.length > 0",
            rounded,
            outlined,
            color="primary",
            @click="showListDialog('WEEKS')"
          ) 
            | {{ `~${selectedWeek.end_date}` }}
            font-awesome-icon.ml-2(:icon="['fas', 'caret-down']")

          v-spacer
          .get_achievement_container.d-flex.align-center(
            v-if="achievementData.complete_count > 0 && !achievementData.is_rewarded"
          )
            svg-icon(
              iconClass="gc_get_achievement_icon",
              className="gc_get_achievement_icon"
            )
            .achievement_btn_container.d-flex.flex-column.flex-sm-row
              span.font-weight-bold.text_deep_black.text-center 領取本週獎勵: {{ achievementData.complete_count }}
              .get_achievement_btn(@click="checkoutAchievement") 領取
        .achievement_category_container.d-flex.flex-wrap
          .achievement_category(v-for="category in achievementData.categories")
            .category_head
              span {{ getNameByCategory(category.name) }}
            template(v-for="achievement in category.data")
              .category_item(:class="{ owned: achievement.is_complete }")
                img.achievement_badge(:src="achievement.icon.image")
                .achievement_name
                  span {{ achievement.name }}
      .achievement_brochure.month.pb-6.px-7(v-if="tab == 1")
        .selector_container.d-flex.align-center.justify-space-between
          v-btn.selected.select_month(
            v-if="seasonData != null && seasonData.end_months != null && seasonData.end_months.length > 0",
            rounded,
            outlined,
            color="primary",
            @click="showListDialog('MONTHS')"
          ) 
            | {{ `${selectedMonth.year}${String(selectedMonth.month).padStart(2, '0')}` }}

            font-awesome-icon.ml-2(:icon="['fas', 'caret-down']")

          v-spacer
          .get_achievement_container.d-flex.align-center(
            v-if="achievementData.complete_count > 0 && !achievementData.is_rewarded"
          )
            svg-icon(
              iconClass="gc_get_achievement_icon",
              className="gc_get_achievement_icon"
            )
            .achievement_btn_container.d-flex.flex-column.flex-sm-row
            span.font-weight-bold.text_deep_black.text-center 領取本月獎勵: {{ achievementData.complete_count }}
            .get_achievement_btn(@click="checkoutAchievement") 領取
        .achievement_category_container.d-flex.flex-wrap
          .achievement_category(v-for="category in achievementData.categories")
            .category_head
              span {{ getNameByCategory(category.name) }}
            template(v-for="achievement in category.data")
              .category_item(:class="{ owned: achievement.is_complete }")
                img.achievement_badge(:src="achievement.icon.image")
                .achievement_name
                  span {{ achievement.name }}
    v-card-actions.justify-center
      v-btn(small, outlined, color="dark-1", text="", @click="closeDialog") close
  dialog-success(
    :title="dialogSuccessData.title",
    @closeSuccessDialog="closeSuccessDialog",
    :dialogSuccess="dialogSuccessShow"
  )
    template(#content)
      p {{ dialogSuccessData.text }}
  dialog-normal(
    :title="dialogListTitle",
    :scroll="true",
    @closeNormalDialog="closeListDialog",
    :dialogNormal="dialogListShow",
    :icon="null",
    :disabled="dialogListShow",
    :maxWidth="400",
    :btn="'Close'"
  )
    template(#content)
      v-list
        v-list-item-group(v-model="model", color="primary", mandatory="")
          v-list-item(
            v-for="(data, i) in currentSelectedDateTypeList",
            :key="i",
            @click="dateListItemClick(data)"
          )
            v-list-item-content
              v-list-item-title
                v-badge(:color="data.has_reward ? 'red' : 'transparent'", dot)
                  span {{ getCurrentSelectedDateText(data) }}
</template>

<script>
import DialogSuccess from "@/components/dialog/DialogSuccess";
import DialogError from "@/components/dialog/DialogError";
import DialogLoading from "@/components/dialog/DialogLoading";
import DialogNormal from "@/components/dialog/DialogNormal";

import { getUserSeasonData, getAchievement } from "@/api/fantasy";

export default {
  name: "DialogAchievement",
  props: {
    dialogAchievement: Boolean,
    needUpdate: Boolean,
  },
  components: {
    DialogSuccess,
    DialogError,
    DialogLoading,
    DialogNormal,
  },
  data() {
    return {
      tab: 0,
      dialogSuccessShow: false,
      dialogSuccessData: {
        title: "",
        text: "",
      },
      dialogListShow: false,
      dialogListTitle: "",
      seasonData: {},
      achievementData: {},
      currentSelectedDateTypeList: [],
      selectedWeek: undefined,
      selectedMonth: undefined,

      model: 0,
    };
  },
  created() {
    this.getUserSeasonData();
  },
  methods: {
    dateListItemClick(data) {
      if (this.tab == 0) {
        this.selectedWeek = data;
      } else {
        this.selectedMonth = data;
      }

      this.getAchievement();
      this.closeListDialog();
    },
    getUserSeasonData() {
      let temp = {
        state: "IN_SEASON",
      };
      getUserSeasonData(temp).then((response) => {
        this.seasonData = response.data[0];

        if (this.seasonData.end_weeks.length > 0) {
          this.selectedWeek = this.seasonData.end_weeks[0];
        }

        if (this.seasonData.end_months.length > 0) {
          this.selectedMonth = this.seasonData.end_months[0];
        }

        this.getAchievement();
      });
    },
    getAchievement() {
      var record_date_type, record_date_id;
      if (this.tab == 0) {
        record_date_type = "WEEK";
        record_date_id = this.selectedWeek.id;
      } else {
        record_date_type = "MONTH";
        record_date_id = this.selectedMonth.id;
      }

      if (record_date_id === undefined) {
        return;
      }

      let temp = {
        season_id: this.seasonData.id,
        record_date_type: record_date_type,
        record_date_id: record_date_id,
      };

      getAchievement(temp).then((response) => {
        this.achievementData = response.data;
        if (this.tab == 0) {
          this.currentSelectedDateTypeList = this.achievementData.weeks;
        } else {
          this.currentSelectedDateTypeList = this.achievementData.months;
        }
      });
    },
    getNameByCategory(category) {
      switch (category) {
        case "ranking":
          return "積分";
        case "ranking_pts":
          return "得分";
        case "ranking_reb":
          return "籃板";
        case "ranking_ast":
          return "助攻";
        case "ranking_stl":
          return "抄截";
        case "ranking_blk":
          return "火鍋";
        default:
          return category;
      }
    },
    weekSelected() {
      this.getAchievement();
    },
    monthSelected() {
      this.getAchievement();
    },
    closeDialog() {
      this.$emit("closeAchievementDialog");
    },
    showSuccessDialog(title, text) {
      this.dialogSuccessData.title = title;
      this.dialogSuccessData.text = text;
      this.dialogSuccessShow = true;
    },
    closeSuccessDialog() {
      this.dialogSuccessData.title = "";
      this.dialogSuccessData.text = "";
      this.dialogSuccessShow = false;
    },
    checkoutAchievement() {
      var record_date_type, record_date_id;
      if (this.tab == 0) {
        record_date_type = "WEEK";
        record_date_id = this.selectedWeek.id;
      } else {
        record_date_type = "MONTH";
        record_date_id = this.selectedMonth.id;
      }

      if (record_date_id === undefined) {
        return;
      }

      var rewards = [];
      this.achievementData.categories.forEach((category) => {
        category.data.forEach((achievement) => {
          if (achievement.is_complete) {
            rewards.push(achievement);
          }
        });
      });

      this.$emit(
        "checkoutAchievement",
        this.seasonData.id,
        record_date_type,
        record_date_id,
        rewards
      );
    },
    closeLoadingDialog() {
      this.dialogLoadingData.text = "";
      this.dialogLoadingShow = false;
    },
    showListDialog(type) {
      if (type == "WEEKS") {
        this.dialogListTitle = "選擇週數";
      } else {
        this.dialogListTitle = "選擇月份";
      }
      this.dialogListShow = true;
    },
    closeListDialog() {
      this.dialogListTitle = "";
      this.dialogListShow = false;
    },
    getCurrentSelectedDateText(data) {
      if (this.tab == 0) {
        return data.start_date + " ~ " + data.end_date;
      } else {
        return data.year + " - " + String(data.month).padStart(2, "0");
      }
    },
  },
  watch: {
    tab() {
      if (this.tab == 0) {
        this.selectedWeek = this.achievementData.weeks[0];
      } else {
        this.selectedMonth = this.achievementData.months[0];
      }
      this.model = 0;

      this.getAchievement();
    },
    needUpdate() {
      if (this.needUpdate) {
        this.getAchievement();
        this.$emit("setAchievementUpdated");
      }
    },
    dialogAchievement() {
      if (this.dialogAchievement) {
        this.tab = 0;
        this.selectedWeek = this.achievementData.weeks[0];
        this.model = 0;
      }
    },
  },
  computed: {},
};
</script>

<style lang="scss" scoped>
@import "@/assets/style/common/table";

::v-deep .v-dialog {
  background-image: url(~@/assets/img/fantasy/achievement_brochure_bg.svg),
    linear-gradient(270deg, #e2ddd4 0%, #f4d9a2 100%);
  background-position: 95% -5%;
}

::v-deep .v-card {
  background: transparent;
}

svg.gc_achievement_icon {
  width: 60px;
  height: 60px;
}

.achievement-sheet {
  border-bottom: 5px solid white;
}

.achievement_tab.new {
  &::after {
    content: "";
    background: #f0353f;
    width: 5px;
    height: 5px;
    border-radius: 50%;
    transform: translateY(-6px);
    margin-left: 2px;
  }
}

.achievement_brochure {
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  background: white;
  width: 100%;

  .selector_container {
    min-height: 80px;
  }

  .achievement_category {
    flex-basis: 16.6%;
    text-align: center;
    border-left: 0.5px solid #dddddd;
    border-right: 0.5px solid #dddddd;
    border-bottom: 1px solid #dddddd;

    // border-right: 1px solid #dddddd;

    &:first-child {
      // border-left: 1px solid #dddddd;
    }

    .category_head {
      height: 50px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      span {
        display: block;
        color: white;
        font-weight: bold;
      }
    }

    .category_item {
      display: flex;
      flex-direction: column;
      align-items: center;
      // border-top: 1px solid #dddddd;
      height: 100px;
      min-width: 100px;
      padding-left: 4px;
      padding-right: 4px;

      .achievement_badge {
        width: 74px;
        height: 74px;
        filter: grayscale(1);
        opacity: 0.3;
      }

      .achievement_name {
        color: #1f1f1f;
        font-weight: bold;
        font-size: 13px;
        filter: grayscale(1);
        opacity: 0.3;
        line-height: 14px;
        min-height: 30px;
      }
    }

    .category_item.owned {
      .achievement_badge,
      .achievement_name {
        filter: none;
        opacity: 1;
      }
    }
  }
}
.achievement_brochure.week {
  .category_head {
    background: #776e5a;
  }

  .achievement_category {
    background: #fcf8ee;
  }
}

.achievement_brochure.month {
  .category_head {
    background: #737373;
  }

  .achievement_category {
    background: #f3f3f3;
  }
}

.gc_get_achievement_icon {
  width: 72px;
  height: 62px;
}

.get_achievement_btn {
  background: #ffffff;
  border: 1px solid #8eb0d6;
  border-radius: 15px;
  padding: 2px 40px;
  color: #1f1f1f;
  margin-left: 12px;
  box-shadow: 0 1px 10px rgba(#000000, 0.1);
  font-weight: bold;
  cursor: pointer;
  text-align: center;
}

.achievement_category_container {
  width: 100%;
  // border-bottom: 1px solid #dddddd;
}

@media (max-width: 1024px) {
  .achievement_brochure {
    .achievement_category {
      flex-basis: 16.6%;
      text-align: center;
    }
  }
}

@media (max-width: 959px) {
  .achievement_brochure {
    .achievement_category {
      flex-basis: 25%;
      text-align: center;
    }
  }
}

@media (max-width: 680px) {
  .achievement_brochure {
    .achievement_category {
      flex-basis: 25%;
      text-align: center;
    }
  }
}

@media (max-width: 599px) {
  ::v-deep .v-dialog {
    margin: 0;
    max-height: 95%;
  }
  .get_achievement_btn {
    padding: 2px 10px;
  }
}

@media (max-width: 460px) {
  .achievement_brochure {
    .achievement_category {
      flex-basis: 33%;
      text-align: center;
    }
  }
}

@media (max-width: 399px) {
  .selector_container {
    flex-direction: column;
  }

  button.selected {
    width: 100%;
  }

  .achievement_btn_container {
    flex-direction: row !important;
  }
}

@media (max-width: 369px) {
  .achievement_brochure {
    padding-left: 12px !important;
    padding-right: 12px !important;

    .achievement_category {
      flex-basis: 50%;
      text-align: center;
    }
  }

  button.selected {
    margin-top: 12px;
  }
}

::v-deep .v-dialog {
  width: 100%;
}
</style>
