<template lang="pug">
v-dialog(v-model="dialogReceiving", max-width="290", persistent)
  v-card.pb-4
    v-card-title.flex-column.justify-center
      .text-h6.mt-6(style="color: #878787") 領取中...
      svg-icon.mb-6(iconClass="receiving_icon", className="receiving_icon")
    v-card-text.text-center
      slot(name="content")
</template>

<script>
export default {
  name: "DialogReceiving",
  props: {
    dialogReceiving: Boolean,
  },
  methods: {},
};
</script>

<style scoped>
svg.receiving_icon {
  width: 120px;
  height: 120px;
}

::v-deep .v-dialog {
  width: 100%;
}
</style>
