<template lang="pug">
.fantasy.bg_f_pale_blue(
  :style="{ marginTop: $store.state.navbarHeight + 'px' }"
)
  v-container.pa-0.py-md-10
    v-row.fantasy_title.d-none.d-md-flex(justify="space-between")
      v-col(cols="auto")
        svg-icon(iconClass="f_logo", className="f_logo")
      v-col(cols="auto")
        .user_symbol.d-flex
          .game_rule.d-md-none(@click="showNormalDialog")
            font-awesome-icon.mr-2(:icon="['fas', 'headset']")
          img.user_avater.avater_sm(
            v-show="$store.state.user.accessToken && !$store.state.user.photo",
            :src="gcAvatar"
          )
          img.user_avater.avater_sm(
            v-show="$store.state.user.photo",
            :src="$store.state.user.photo"
          )
          span.user_name {{ $store.state.user.name }}
    v-row.ma-0.ma-md-3
      .main_board.bg_white.radius.mt_6.pb-10
        .main_tabs.position-relative.d-none.d-md-block
          v-tabs.f-sheet-s-border(v-model="tab", ripple="false")
            v-tabs-slider(hidden="true")
            v-tab(:ripple="false", @click="toOverview") 
              font-awesome-icon.mr-2(:icon="['fas', 'th-large']")
              | 總覽
            v-tab(:ripple="false", @click="toMyteam") 
              font-awesome-icon.mr-2(:icon="['fas', 'basketball-ball']")
              | 我的球隊
            v-tab(:ripple="false", @click="toMyLeague") 
              font-awesome-icon.mr-2(:icon="['fas', 'trophy']")
              | 我的聯賽
            v-tab(:ripple="false", @click="toDraft") 
              font-awesome-icon.mr-2(:icon="['fas', 'star']")
              | 球員分析
          .game_rule.d-none.d-md-block(@click="showNormalDialog")
            font-awesome-icon.mr-2(:icon="['fas', 'headset']")
            | 遊戲規則
        transition(name="page", mode="out-in")
          router-view(
            @showNormalDialog="showNormalDialog",
            @showAchievementDialog="showAchievementDialog",
            :needUpdate="needUpdate"
          )
  //- custom-footer
  .fantasy_menu.bg_f_pale_blue.d-flex.d-md-none
    .logo
      svg-icon(iconClass="f_logo_s", className="f_logo_s")
    .main_tabs.position-relative.flex-grow-1
      v-tabs.f-app-style(v-model="tab", ripple="false")
        v-tabs-slider(hidden="true")
        v-tab(:ripple="false", @click="toOverview") 
          font-awesome-icon(:icon="['fas', 'th-large']")
          p 總覽
        v-tab(:ripple="false", @click="toMyteam") 
          font-awesome-icon(:icon="['fas', 'basketball-ball']")
          p 我的球隊
        v-tab(:ripple="false", @click="toMyLeague") 
          font-awesome-icon(:icon="['fas', 'trophy']")
          p 我的聯賽
        v-tab(:ripple="false", @click="toDraft") 
          font-awesome-icon(:icon="['fas', 'star']")
          p 球員分析
        v-tab(:ripple="false", @click="showNormalDialog") 
          font-awesome-icon(:icon="['fas', 'headset']")
          p 規則
  dialog-normal(
    :scroll="true",
    @closeNormalDialog="closeNormalDialog",
    :dialogNormal="dialogNormalShow",
    :icon="null",
    :disabled="dialogNormalShow",
    :maxWidth="800"
  )
    template(#title)
      .game_rule_title.d-flex.align-center
        font-awesome-icon.mr-2(:icon="['fas', 'headset']")
        span.text-h5 {{ gameRule.title }}
    template(#content)
      .rule_content.text-left(
        style="color: black",
        v-if="seasonData != undefined && seasonData.config != undefined"
      )
        .intro.text-center.pb-2.pt-5(
          style="border-top: #d3d3d3 1px solid; border-bottom: #d3d3d3 1px solid"
        )
          p 歡迎加入GC Fantasy
          p 一個能讓大家透過台灣籃球更加了解台灣籃球的社群遊戲
          p ↓以下介紹遊戲規則↓
        h2.font-weight-bold.mb-4.mt-8 選擇球員
        ul
          li 每位會員可以創建一個隊伍，隊伍的總價值上限為{{ seasonData.config.max_player_value }}。
          li 必須選擇{{ seasonData.config.team_max_local_player }}位本土球員，不可超過總價值。
          li 必須選擇{{ seasonData.config.team_max_foreign_player }}位洋將，洋將不計算在總價值中。
          li 本土球員：每週最多可以更換{{ seasonData.config.weekly_local_player_transaction_max_count / 2 }}位本土球員。
          li 洋將球員：每週最多可以更換{{ seasonData.config.weekly_foreign_player_transaction_max_count / 2 }}位洋將球員。
        h2.font-weight-bold.mb-4.mt-8 參加比賽
        ul
          li 每場需從隊伍中挑選{{ seasonData.config.daily_local_player_g_count }}個G、{{ seasonData.config.daily_local_player_f_count }}個F、{{ seasonData.config.daily_local_player_c_count }}個C及{{ seasonData.config.daily_local_player_u_count }}個自由位置的本土球員上場。
          li 洋將不需要特別挑選，系統會自動選取{{ seasonData.config.daily_foreign_player_count }}位表現最好的洋將加入分數計算，。
          li 每天都可以更改上場陣容，若當日有比賽，會在第一場比賽開始前1小時鎖定陣容安排。
        h2.font-weight-bold.mb-4.mt-8 計分方式
        h4.mb-4.pa-3.rounded(style="border: #dee2e6 2px solid") 積分 = 總得分 + 總籃板 + 總助攻 + 總抄截 + 總火鍋。
        .notice.pa-2.rounded(style="background: #dee2e6")
          h3 備註：
          ul
            li 若因球員價值成長使球隊總價值超過上限{{ seasonData.config.max_player_value + 20 }}，會開始進入豪華稅時間，如不盡快調整隊伍，每場比賽會依照當下價值每超過10分為區間扣除10%積分，扣除上限為50%。
            li 若球隊總價值已超過總價值進行交易時，只能換交換該球員價值以下的球員。
            li 積分採用GC團隊自記數據，若與官方數據有些微誤差，以GC團隊紀錄之數據為準。
            li IG排行榜入榜標準：
            li 由於此遊戲為首次運行，為維持遊戲公平性與合理性，GC團隊保留規則修改之權利。
            li 每週積分最晚於每週二24:00更新。
  dialog-receiving(:dialogReceiving="dialogReceivingShow")
  dialog-get-achievement-success(
    @closeGetAchievementSuccessDialog="closeGetAchievementSuccessDialog",
    :dialogGetAchievementSuccess="dialogGetAchievementSuccessShow",
    :rewards="rewards",
    :reward_total="reward_total"
  )
  dialog-achievement(
    ref="dialogAchievement"
    @closeAchievementDialog="closeAchievementDialog",
    @checkoutAchievement="checkoutAchievement",
    @setAchievementUpdated="setCheckoutAchievementUpdated",
    :needUpdate="needUpdate",
    :dialogAchievement="dialogAchievementShow"
  )
</template>

<script>
import CustomFooter from "@/components/common/Footer";
import { getSeasonData, checkoutAchievementReward } from "@/api/fantasy";
import DialogNormal from "@/components/dialog/DialogNormal";
import DialogAchievement from "@/components/fantasy/achievement/DialogAchievement";
import DialogReceiving from "@/components/fantasy/achievement/DialogReceiving";
import DialogGetAchievementSuccess from "@/components/fantasy/achievement/DialogGetAchievementSuccess";
import gcAvatar from "@/assets/img/gc-avatar.png";

export default {
  name: "Fantasy",
  components: {
    CustomFooter,
    DialogNormal,
    DialogAchievement,
    DialogReceiving,
    DialogGetAchievementSuccess,
  },
  data() {
    return {
      gcAvatar,
      tab: 0,
      seasonData: {},
      gameRule: {
        title: "遊戲規則",
      },
      dialogNormalShow: false,
      dialogAchievementShow: false,
      dialogReceivingShow: false,
      dialogGetAchievementSuccessShow: false,
      needUpdate: false,
      rewards: [],
      reward_total: 0,
    };
  },
  watch: {
    "$route.name"() {
      this.checkTab();
    },
  },
  created() {
    this.getSeasonData();
    if (this.$route.name != null) {
      this.checkTab();
    }
  },
  methods: {
    getSeasonData() {
      let temp = {
        state: "IN_SEASON",
      };
      getSeasonData(temp).then((response) => {
        this.seasonData = response.data[0];
      });
    },
    toOverview() {
      this.$router.push({ name: "Overview" });
    },
    toDraft() {
      this.$router.push({ name: "Draft" });
    },
    toMyteam() {
      this.$router.push({ name: "MyTeam" });
    },
    toMyLeague() {
      this.$router.push({ name: "MyLeague" });
    },
    checkTab() {
      if (this.$route.name == "Overview") {
        this.$nextTick(() => {
          this.tab = 0;
        });
      } else if (
        this.$route.name == "MyTeam" ||
        this.$route.name == "CreateTeam"
      ) {
        this.$nextTick(() => {
          this.tab = 1;
        });
      } else if (this.$route.name == "MyLeague") {
        this.$nextTick(() => {
          this.tab = 2;
        });
      } else if (this.$route.name == "Draft") {
        this.$nextTick(() => {
          this.tab = 3;
        });
      }
    },
    showAchievementDialog() {
      this.dialogAchievementShow = true;
      if (this.$refs.dialogAchievement) {
        this.$refs.dialogAchievement.getAchievement();
      }
    },
    closeAchievementDialog() {
      this.dialogAchievementShow = false;
    },
    checkoutAchievement(season_id, record_date_type, record_date_id, rewards) {
      this.dialogReceivingShow = true;
      this.rewards = rewards;
      var reward_total = 0;
      rewards.forEach((reward) => (reward_total += reward.reward));
      this.reward_total = reward_total;

      var self = this;

      let temp = {
        season_id: season_id,
        record_date_type: record_date_type,
        record_date_id: record_date_id,
      };
      checkoutAchievementReward(temp).then(() => {
        self.closeReceivingDialog();
        self.needUpdate = true;
        self.showGetAchievementSuccessDialog();
      });
    },
    setCheckoutAchievementUpdated() {
      this.needUpdate = false;
    },
    closeReceivingDialog() {
      this.dialogReceivingShow = false;
    },
    showGetAchievementSuccessDialog() {
      this.dialogGetAchievementSuccessShow = true;
    },
    closeGetAchievementSuccessDialog() {
      this.dialogGetAchievementSuccessShow = false;
    },
    showNormalDialog() {
      this.dialogNormalShow = true;
      this.$nextTick(() => {
        this.checkTab();
      });
    },
    closeNormalDialog() {
      this.dialogNormalShow = false;
    },
  },
};
</script>

<style lang="scss" scoped>
img.user_avater {
  border-radius: 50%;
  object-fit: cover;
  background: #d8d8d8;
}

.theme--light.v-sheet {
  li {
    .ranking {
      // color: $lightGray;
      flex-basis: 30px;
    }

    &:first-child {
      .ranking {
        // color: white;
      }
    }
  }
}

svg.f_logo {
  width: 167px;
  height: 32px;
}

.user_name {
  font-weight: 700;
  align-self: center;
  margin-left: 8px;
  max-width: 140px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.main_board {
  width: 100%;
  padding-top: 65px;
  padding-left: 45px;
  padding-right: 45px;
}

.main_tabs {
  position: relative;

  &::before {
    content: "";
    position: absolute;
    display: block;
    background-color: $f_line_gray;
    width: 100%;
    bottom: 0px;
    left: 1;
    height: 1px;
  }
}

.avater_sm {
  width: 36px;
  height: 36px;
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.5);
  border: 1px solid #ffffff;
}

.game_rule {
  color: #6a72d6;
  font-size: 14px;
  font-weight: bold;
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
  cursor: pointer;
}

.game_rule_title {
  color: #6a72d6;
}

.rule_content {
  li {
    line-height: 30px;
  }
  h2 {
    margin-bottom: 20px;
  }
  li {
    list-style-type: decimal;
    margin-bottom: 10px;
  }

  .notice {
    .detail {
      li {
        list-style-type: disc;
      }
    }
  }
}

::v-deep .theme--light.v-tabs > .v-tabs-bar {
  background: transparent !important;
}

@media (max-width: 959px) {
  .v-tabs {
    background-color: $f_pale_blue;
  }

  svg.f_logo {
    width: 125px;
    height: 24px;
    vertical-align: middle;
    vertical-align: -webkit-baseline-middle;
  }

  // .fantasy_title {
  //   position: fixed;
  //   bottom: 0;
  //   width: 100%;
  //   background-color: $f_pale_blue;
  //   z-index: 3;
  //   height: 60px;
  //   margin: 0;
  //   padding: 0;
  //   left: 0;

  //   .user_symbol {
  //     .user_name {
  //       display: none;
  //     }
  //   }
  // }

  .fantasy_menu {
    height: 45px;
    position: fixed;
    bottom: 0;
    width: 100%;
    z-index: 3;
    margin: 0;
    padding: 0;
    left: 0;

    .logo {
      width: 14%;
      position: relative;

      &:after {
        content: "";
        height: 80%;
        width: 1px;
        background: #e2e2e2;
        display: block;
        position: absolute;
        right: 0;
        top: 50%;
        transform: translateY(-50%);
      }

      .f_logo_s {
        margin-top: 13.5px;
        margin-left: auto;
        margin-right: auto;
        display: block;
      }
    }

    .main_tabs {
      width: 86%;
    }
  }

  .main_board {
    padding: 0;
    border-radius: 0;
    padding-bottom: 0 !important;
  }

  .my_team {
    display: flex;
    flex-direction: column-reverse;
  }

  .game_rule {
    position: relative;
    top: initial;
    right: initial;
    transform: none;
    font-size: 24px;
    margin-right: 5px;
  }
}
</style>
